/*
WEB用ヘルプページ
*/
import * as React from "react"
import { graphql } from "gatsby"
import "../helpPage.css"
import Layout from "../components/layout"
import Seo from "../components/seo"
import HelpContents from "./HelpContents"
import girl2 from "../images/girls/2.png"
import girl24 from "../images/girls/24.png"
import girl19 from "../images/girls/19.png"
import girl14 from "../images/girls/14.png"

const HelpPage = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout location={location} title={siteTitle}>
      <Seo title="ヘルプ" />
      <section className="PageTitle" id="pagetop">
        <div class="wrap">
          <img src={ girl14 } className="girls_img"/>
          <img src={ girl2 } className="girls_img"/>
          <div>
            <h2>[　Help　]</h2>
            <h1>NuRseCallに関する<br />ヘルプページです</h1>
          </div>
          <img src={ girl19 } className="girls_img"/>
          <img src={ girl24 } className="girls_img"/>
        </div>
        <div className="area">
          <ul class="circles">
            <li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li>
          </ul>
        </div>
      </section>
      <section className="HelpWeb">
        <div className="wrap">
        <HelpContents>読み込み中...</HelpContents>
        </div>
      </section>
    </Layout>
  )
}

export default HelpPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
